import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getKeys } from 'customer/cacheKeys';
import useProfile from 'customer/components/iam/Profile/hooks';
import { TreasuryAccount } from 'types/emoney/Treasury';
import services from './service';

export const useTreasuryAccountList = () => {
  const { profileId } = useProfile();
  const {
    data: treasuryAccounts,
    isPending,
    isSuccess,
    error,
  } = useQuery<TreasuryAccount[]>({
    queryKey: getKeys.treasuryList,
    queryFn: async () => services.list({ profileId }),
    enabled: !!profileId,
    staleTime: 1000 * 60, // Stale after 1 minutes
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  return {
    treasuryAccounts: treasuryAccounts as TreasuryAccount[],
    isPending,
    isSuccess,
    error,
  };
};

export const useTreasuryAccountCreate = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationKey: getKeys.treasuryCreate,
    mutationFn: services.create,
    onSuccess() {
      // Refetch all accounts on success.
      queryClient.invalidateQueries({
        queryKey: getKeys.treasuryList,
      });
    },
    // onError not needed,
    // there is a global catch and notify errors
    // see setMutationDefaults in ReactQueryProvider
  });
  return {
    createTreasuryAccount: mutateAsync,
    isPending,
    isError,
    error,
  };
};
export const useTreasuryAccountUpdate = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending, isError, error } = useMutation({
    mutationKey: getKeys.treasuryUpdate,
    mutationFn: services.update,
    onSuccess(data, variables) {
      // Refetch all accounts on success.
      queryClient.invalidateQueries({
        queryKey: getKeys.treasuryList,
      });
      queryClient.invalidateQueries({
        queryKey: getKeys.treasuryRead(variables.accountId),
      });
    },
    // onError not needed,
    // there is a global catch and notify errors
    // see setMutationDefaults in ReactQueryProvider
  });
  return {
    updateTreasuryAccount: mutateAsync,
    isPending,
    isError,
    error,
  };
};

export const useTreasuryAccountRead = (accountId: string) => {
  const { data, isPending, isSuccess, error } = useQuery<TreasuryAccount>({
    queryKey: getKeys.treasuryRead(accountId),
    queryFn: async () => services.read(accountId),
    enabled: !!accountId,
    staleTime: 1000 * 60, // Stale after 1 minutes
    refetchOnMount: true,
    refetchOnReconnect: true,
  });

  return {
    selectedTreasuryAccount: data as TreasuryAccount,
    isPending,
    isSuccess,
    error,
  };
};
