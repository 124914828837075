import { equal } from 'src/utils/address';
import { Counterpart } from '../../emoney/Counterpart/types';

import { Order } from 'types/emoney/Order';
import {
  CrossChainIdentifier,
  IbanIdentifier,
  ScanIdentifier,
  Standard,
} from '../Identifier/types';
import { TreasuryAccount } from './types';

export const shortenIban = (iban?: string) => {
  if (typeof iban !== 'string' || !iban?.length) return iban;
  const ns = iban.replace(/\s/g, ''); // remove spaces
  return iban?.length > 11
    ? `${ns.substring(0, 4)}...${ns.substring(ns.length - 4)}`
    : iban;
};

export const filterAccounts = (
  emoneyAccountId?: string,
  list?: TreasuryAccount[],
) => {
  if (!list?.length) return [];
  if (emoneyAccountId)
    return list.filter((i) => i.emoneyAccountId === emoneyAccountId);
  return list;
};

export default {
  shortenIban,
  filterAccounts,
};

export const getLatestCounterparts = (
  list: Order[],
  standard: Standard = 'iban',
  accountId?: string,
): (Counterpart | undefined)[] => {
  const allCounterparts = list
    ?.filter((i) => (accountId ? i.accountId === accountId : true))
    ?.filter((i) => i?.meta?.state === 'processed')
    ?.sort(
      (x, y) =>
        new Date(y.meta.placedAt).getTime() -
        new Date(x.meta.placedAt).getTime(),
    )
    .map((i) => i.counterpart)
    .filter((i) => i?.identifier.standard === standard);

  const uniqueCounterparts: Counterpart[] = [];

  // check if identifier is already in the uniqueCounterparts array
  function identifierExists(
    identifier: IbanIdentifier | ScanIdentifier | CrossChainIdentifier,
  ) {
    return uniqueCounterparts.some((ucp) => {
      if (standard === 'iban') {
        return (
          (identifier as IbanIdentifier)?.iban ===
          (ucp?.identifier as IbanIdentifier)?.iban
        );
      }
      if (standard === 'scan') {
        const current = identifier as ScanIdentifier;
        const existing = ucp?.identifier as ScanIdentifier;
        return (
          current?.sortCode === existing?.sortCode &&
          current?.accountNumber === existing?.accountNumber
        );
      }
      if (standard === 'chain') {
        const current = identifier as CrossChainIdentifier;
        const existing = ucp?.identifier as CrossChainIdentifier;
        return (
          equal(current?.address, existing?.address) &&
          current?.chain === existing?.chain &&
          current?.network === existing?.network
        );
      }
      return false;
    });
  }

  allCounterparts?.forEach((currentCounterpart) => {
    if (!identifierExists((currentCounterpart as Counterpart)?.identifier)) {
      if (currentCounterpart) uniqueCounterparts.push(currentCounterpart);
    }
  });

  const latestFive = uniqueCounterparts?.slice(0, 5);
  return latestFive;
};

export const isCurrencySupported = (
  currency?: string,
  supportedCurrency?: string[],
) =>
  !!supportedCurrency?.find((c) => currency?.toLowerCase() === c.toLowerCase());
