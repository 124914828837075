import restQuery from 'services/rest-query';

import { TreasuryAccount } from 'types/emoney/Treasury';
import { NewTreasuryAccountRequest } from './types';

export default {
  list: async ({
    profileId,
  }: {
    profileId: string;
  }): Promise<TreasuryAccount[]> => {
    return restQuery
      .get(`/api/treasury/profiles/${profileId}/accounts`)
      .then((a) => {
        if (Array.isArray(a)) {
          return a.filter(
            (i) => (i as TreasuryAccount)?.meta?.state !== 'closed',
          );
        }
        return [];
      });
  },
  create: async ({
    profileId,
    payload,
  }: {
    profileId: string;
    payload: NewTreasuryAccountRequest;
  }): Promise<TreasuryAccount> => {
    return restQuery.post(
      `/api/treasury/profiles/${profileId}/accounts`,
      JSON.stringify(payload),
    );
  },
  update: async ({
    accountId,
    payload,
  }: {
    accountId: string;
    payload: Partial<TreasuryAccount>;
  }): Promise<TreasuryAccount> => {
    return restQuery.patch(
      `/api/treasury/accounts/${accountId}`,
      JSON.stringify(payload),
    );
  },
  read: async (id: string): Promise<TreasuryAccount> => {
    return restQuery.get(`/api/treasury/accounts/${id}`);
  },
};
